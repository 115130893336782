.ws-model-selector {
	.tab-controls-container {
		.tab-control {
			top: 5px;
		}

		.tab-control.left-control {
			left: 5px;
		}

		.tab-control.right-control {
			right: 5px;
		}
	}

	// General slider CSS rules
	.slider {
		.slider-content {
			white-space: nowrap;
			scrollbar-width: none;
			-ms-overflow-style: none;
			scroll-snap-type: x mandatory;
			overflow: -moz-scrollbars-none;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.slider-controls {
			top: 50%;
			width: 100%;
		}

		.slider-controls-mobile {
			right: 0;
			bottom: -55px;
		}

		.mobile-button.slider-control-container-left {
			right: 15%;
		}
	}

	.large-vehicle-card {
		width: 100%;
		padding: 0 50px;
		position: relative;
		display: inline-block;
		scroll-snap-align: start;
	}

	div.nav-pills.carousel {
		justify-content: center;
	}

	.nav-pills > button {
		background-color: transparent;
		padding: 5.5px 1em 6.5px 1em;
		border-radius: 1.5em;
		border: 1px solid transparent;
		font-weight: normal;
		margin: 7px 0;
		text-decoration: none;
	}

	&.dark-background-model-selector .carousel-card .model,
	&.dark-background-model-selector .large-vehicle-card,
	&.dark-background-model-selector .nav-pills > button {
		color: var(--color-neutral-0);
	}

	.nav-pills > button:hover {
		background-color: var(--page-bg);
		border: 1px solid var(--color-neutral-100);
	}

	&.dark-background-model-selector .nav-pills button.active {
		background-color: var(--color-primary-500);
	}

	&.dark-background-model-selector .nav-pills > button:hover {
		color: var(--color-neutral-1000);
		background-color: var(--color-neutral-0);
	}

	.nav-pills:hover > button.active {
		border-color: transparent;
		background-color: var(--color-primary-500);
		color: var(--color-neutral-0);
	}

	.nav.nav-pills > button.active {
		background-color: var(--color-primary-500);
		color: var(--color-neutral-0);
	}

	&.dark-background-model-selector .nav-pills button.active:hover {
		color: var(--color-neutral-0);
	}

	&.dark-background-model-selector .widget-heading {
		color: var(--color-neutral-0);
	}

	.nav-tabs {
		display: flex;
		flex-wrap: wrap;
	}

	.tabs .nav-tabs {
		justify-content: end;

		@media (max-width: 768px) {
			padding-bottom: 24px;
		}
	}

	.tabs .nav-pills {
		display: flex;
		overflow-x: auto;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;

			/* Safari and Chrome */
		}

		padding-bottom: 8px;
		margin: 0 25px 0 25px;
		justify-content: flex-start;

		@media (min-width: 1200px) {
			justify-content: end;
		}
	}

	.model-card-grid-container {
		display: flex;
		flex-wrap: wrap;
	}

	.model-card-title-container {
		text-wrap: wrap;
	}

	.model-card.box,
	.slider-card.box {
		flex-grow: 1;

		.model {
			display: flex;
			flex-direction: column;
			flex: 1;
			justify-content: space-between;
		}
	}

	.image-container {
		justify-content: center;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		}
	}

	.model-card:hover,
	.model-card:focus,
	.carousel-card:hover,
	.carousel-card:focus {
		img {
			transform: 0.2s ease;
			transform: scale(1.09, 1.09);
		}
	}

	.vehicles-carousel {
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		scrollbar-width: none;
		scroll-snap-type: x mandatory;

		&::-webkit-scrollbar {
			display: none;

			/* Safari and Chrome */
		}

		.carousel-card {
			flex-grow: 4;
			min-width: 33.333%;
			max-width: 33.333%;
			position: relative;
			display: inline-flex;
			scroll-snap-align: start;

			.model {
				flex: 1;
			}

			@media (max-width: 1200px) {
				min-width: 50%;
			}

			@media (max-width: 768px) {
				min-width: 100%;
				scroll-snap-align: center;
			}
		}
	}

	.mobile-card {
		min-width: 33.333%;
		display: inline-flex;
		scroll-snap-align: start;

		@media (max-width: 1200px) {
			min-width: 75%;
			margin: 0 8px 0 8px;
			scroll-snap-align: center;

			&:first-child {
				margin-left: 16px;
			}

			&:last-child {
				margin-right: 16px;
			}
		}
	}

	.secondary-nav {
		width: 100%;
		align-items: center;
		display: inline-flex;

		.items-container {
			margin: 0;
			max-width: 80%;
			display: inline-block;
		}

		.line {
			flex: 1;
			height: 0;
			margin: 3px 20px;
			display: inline-block;
			border: 1px solid var(--color-neutral-1000-alpha-2);
		}

		.btn:focus,
		.btn:active {
			outline: 0;
		}
	}

	&.dark-background-model-selector .secondary-nav .line {
		border: 1px solid var(--color-neutral-300);
	}
}
